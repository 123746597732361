

.left_container{
    width: 37%;
    background-color: rgb(38 70 115 / 90%);
    height: 100%;
    margin-left: -1.3%;
    padding-top: 2.5rem;
    padding-left: 3rem;
}

.img-fluid{
    width: 100%;
}

.top_banner_heading_container{
    margin-top: 2rem;
}
.top_banner_heading_container .div1{
    min-width: 355px;
}
.top_banner_heading_container .div2{
    min-width: 365px;
}
.top_banner_heading_container .div3{
    min-width: 375px;
}
.top_banner_heading_container >div{
    background-color: #fff;
    margin-top: 8px;
    color: var(--color11);
    position: relative;
}
.top_banner_heading_container>div h1{
    font-size: 1.8rem !important;
    font-weight: 600;
    position: relative;
    padding-left: 10px;
}
.center .banner_item{
    background-color: var(--color16);
    height: 180px;
    -webkit-box-shadow:inset 0px 0px 0px 2px white;
    -moz-box-shadow:inset 0px 0px 0px 2px white;
    box-shadow:inset 0px 0px 0px 2px white;
}

.banner_item{
    background-color: var(--bg1);
    height: 180px;
    -webkit-box-shadow:inset 0px 0px 0px 2px white;
    -moz-box-shadow:inset 0px 0px 0px 2px white;
    box-shadow:inset 0px 0px 0px 2px white;
}




.events_div{
    height: 26rem;
}

.we_do_box{
    height: 300px;
    color: white !important;
    text-align: left;
    padding: 30px;
}
.we_do_box a{
    position: absolute;
bottom: 0px;
right: 0;
}

.we_do_box i{
    font-size: 64px;
    padding: 20px;
}


.insight_div{
    background-color: var(--color12);
}
.insight_h2{color: white;
padding-bottom: 2rem;}
.insight_card{
    height: 450px;
    overflow: hidden;
}
.podcast_top{ 
    background-color: var(--color11);
    height: 50%;
    color: white;
}

.podcast_mic{
    padding: 20px;
    font-size: 64px;
}

/* .slider2_div{
    background-image: url(https://api.isa.techconfianza.com/storage/images/home_page/bg1.png);
} */

.twitter_timeline{
    margin: 0 -16px;
}
.twitter_timeline div{
    background-color: #292f33;
}



.event_item{
    text-align: left !important;
    overflow: hidden;
    height: 26rem;
  }
  .event_item h3{
    margin: 1rem;
    background-color: #fff;
    display: inline-block !important;
    font-size: 32px !important;
    text-align: left !important;
    line-height: 40px;
    padding: 20px;
    color: var(--color11);
  }
  
  .event_item p{
    margin: 1rem;
    font-size: 18px !important;
    /* margin: 20px 0 !important; */
    text-align: left;
    color: white;
  }
.event_item button{
    margin-left: 1rem;
}
.slider_item_container button{
    font-size: 1rem;
}

/* IN THE NEWS COMPONENTS CSS */

.in_the_news_card{
    height: 62vh;
}

@media only screen and (max-width:1280px){
    .in_the_news_card{
        height: 35vh !important;
    }
}

.banner_image_div{
    overflow: hidden;
    padding-top: 40%;
}
.banne_div_img{
    position: absolute;
    top: 0;
}


#left_container{
    
    position: absolute;
    top: 0;
}
