.owl-nav .disabled,
.disabled {
  display: none !important;
}

#sync2 .item {
  background: #c9c9c9;
}
#sync2 .item h1 {
  font-size: 18px;
}
#sync2 .current .slider_item_container {
  background: #0c83e7 !important;
}

.owl-theme .owl-nav [class*="owl-"] {
  transition: all 0.3s ease;
}
.owl-theme .owl-nav [class*="owl-"].disabled:hover {
  background-color: #d6d6d6;
}
