#menu_my {
    background-color: var(--bg1);
    /* background: #343434; */
    color: var(--color12);
    height: 35px;
    /* border-bottom: 4px solid #eeeded; */
    float: right;
  }
  
  #menu_my ul,
  #menu_my li {
    margin: 0 0;
    padding: 0 0;
    list-style: none
  }
  
  #menu_my ul {
    height: 35px
  }
  
  #menu_my li {
    float: left;
    display: inline;
    position: relative;
    font-weight: bold;
    font-style: italic;
    font-size: 12px;
    /* text-shadow: 0 -1px 0 #000; */
    /* border-right: 1px solid #444;
    border-left: 1px solid #111; */
    /* text-transform: uppercase */
  }
  
  #menu_my li:first-child {
    border-left: none
  }
  
  #menu_my a {
    display: block;
    line-height: 55px;
    padding: 0 14px;
    text-decoration: none;
    color: var(--color12);
  }
  
  #menu_my li:hover > a,
  #menu_my li a:hover {
    background-color: var(--bg1);
    /* background: #111 */
    background-color: var(--color15) !important;
  }
  
  #menu_my input {
    display: none;
    margin: 0 0;
    padding: 0 0;
    width: 80px;
    height: 35px;
    opacity: 0;
    cursor: pointer
  }
  
  #menu_my label {
    font: bold 30px ;
    font-weight: bold;
    font-style: italic;
    display: none;
    width: 35px;
    height: 36px;
    line-height: 36px;
    text-align: center
  }
  
  #menu_my label span {
    font-weight: bold;
    font-style: italic;
    font-size: 12px;
    position: absolute;
    left: 35px
  }
  
  #menu_my ul.menus_my {
    height: auto;
    width: 300px;
    background-color: var(--bg1);
    /* background: #111; */
    position: absolute;
    z-index: 99;
    display: none;
    border: 0;
  }
  
  #menu_my ul.menus_my li {
    display: block;
    width: 100%;
    font-weight: bold;
    font-style: italic;
    font: 12px;
    text-transform: none;
  }
  
  #menu_my li:hover ul.menus_my {
    display: block
  }
  
  /* #menu_my a.home {
    background: #c00;
  }
   */
  #menu_my a.prett {
    padding: 0 27px 0 14px
  }
  
  #menu_my a.prett::after {
    content: "";
    width: 0;
    height: 0;
    border-width: 6px 5px;
    border-style: solid;
    border-color: rgb(0, 0, 0) transparent transparent transparent;
    position: absolute;
    top: 25px;
    right: 9px
  }
  
  #menu_my ul.menus_my a:hover {
    background-color: var(--color15) !important;
  }
  
  #menu_my ul.menus_my .submenu_my {
    display: none;
    position: absolute;
    left: 180px;
    /* background: #111; */
    top: 0;
    width: 180px;
  }
  
  #menu_my ul.menus_my .submenu_my li {
    /* background: #111; */
    background-color: var(--bg1);
  }
  
  #menu_my ul.menus_my .has-submenu_my:hover .submenu_my {
    display: block;
  }


  /* .mycol-10 {
    @media (max-width: 767px) {
      text-align: center;
    }
    @media (max-width: 400px) {
      text-align: left;
    }
  } */