* {
    box-sizing: border-box;
  }
  
  body {
    min-height: 100vh;
    display: flex;
    font-weight: 400;
    
  
    
  }
  
  body, html, .App, #root, .outer {
    width: 100%;
    height: 100%;
  }
  
 
  
  .outer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  
  .singin_container {
    width: 400px !important;
    text-align: left;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2) !important;
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
    position: absolute;
    left: 35%;
    top: 20%;
  }
  
  .outer .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  
  .outer h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
  
  .custom-control-label {
    font-weight: 400;
  }
  
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  
  /* FOR FIREFOX */
  input[type=number]{
    -moz-appearance: textfield;
  }
  
  .send_otp_btn{
    position: absolute;
    margin-top: -2rem;
    margin-left: 13.8rem;
    display: none;
    padding: 10px;
  }

  .form_submit_btn{
    background-color: var(--color21) !important;
    
  }
  
  .form_submit_btn:hover{
    cursor: not-allowed;
  }

  .error_heading{
    margin-top: -15px;
    color: red;
  }