
@media only screen  and (max-width: 768px) {
    .left_container{
        width: 60% !important;
        height: 300px !important;
        top: -2rem !important;
    }
    .top_banner_heading_container{
        margin-top: 0rem;
    }
    .top_banner_heading_container .div1{
        min-width: 225px !important;
    }
    .top_banner_heading_container .div2{
        min-width: 230px !important;
    }
    .top_banner_heading_container .div3{
       min-width: 245px !important;
    }
    .top_banner_heading_container >div{
        margin-top: 1px !important; 
    }
    #home_banner_heading{
        font-size: 1rem !important;
        padding: 2px 0;
        margin: 7px;
    }
    #home_banner_para{
        font-size: .8rem;
        line-height: 1.3;
        padding: 4px 0px 0px 2px !important;
    }
    #home_banner_btn{
        margin: 0%;
        position: absolute;
        right: -11%;
        bottom: 8%;
        padding: 1px !important;
        font-size: .8rem !important;
    }
    .banner_date{
        margin: 0%;
        position: absolute;
        bottom: 8%; 
        padding-right: 2px !important;
    }
    .home_banner_container{
        height: 290px !important;
        position: relative;
        height: auto !important;
    }
    .banner_image_div{
        height: 260px !important;
        position: relative;
    }
    .banner_image_div img{
        height: 260px !important;
    }
}

/* SLIDER2 BOTTOM SECTION */

@media only screen and (max-width: 768px) {
    .owl-nav { 
        display: none !important; 
        flex-direction: column !important;
        text-align: right !important;
        margin-right: 10px !important;
    }
    .slider_item_container h1{
        font-size: 22px !important;
    }
}

/* INSIGHT */
@media only screen and (max-width: 768px) {
    .insight_row .col-sm{
        margin-top: .5rem !important;
    }
  
    .insight_card{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    #podcast_container{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
 .insight_twitter_container{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
 }
}

