/* FONT FAMILY FOR HEADING NOTO SANS */

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');


.in_the_news_div h1 {
    padding-bottom: 1.2rem;
}


.contact_us_social {
    padding: 2rem 1rem;
}

.contact_us_social li {
    list-style: none;
    font-size: 1rem;
}

.contact_us_social a {
    text-decoration: none;
}

.container-contact h1 {
    color: var(--color11);
    font-size: 2rem !important;
    padding-bottom: 1.5rem;
}

.container-contact p {
    font-size: 1rem;
}

.container-contact li a {
    color: var(--color12);
}

/* contact card css */


/* .contact_card_container{
  padding-right: 1.8rem;
} */
.contact_card_row {
    /* width: 102%; */
    height: 33vh;
}


.border-primary {
    border-color: var(--color22) !important;
}

.left_div img {
    object-fit: cover;
    width: 800px;
    height: 100%;
}

.right_div {
    background-color: rgb(224, 227, 227);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.right_div h1 {
    font-size: 24px !important;
}

.contact_card_left_div,
.contact_card_main_col {
    padding-left: 0;
    padding-right: 0;
}

.right_div h2 {
    font-size: 20px !important;
}


.right_div button {
    visibility: hidden;
}

.contact_card_body:hover button {
    visibility: visible;
}

/* ABOUT PAGE TOP BANNER */
.banner_heading_container h1 {
    font-family: 'Noto Serif', serif;
    font-weight: normal !important;
    font-size: 2rem !important;
}

.banner_heading_container h2,
.vison_top_heading_container h2 {
    text-align: left;
    padding: 10px;
    font-size: 2.5rem !important;
    font-family: 'Noto Serif', serif;
    font-weight: 700 !important;
}

.vison_top_heading_container h1 {
    font-weight: normal !important;
    font-size: 2.5rem !important;
    font-family: 'Noto Serif', serif;
}

.page_heading {
    font-size: 1rem !important;
}

.page_heading a {
    text-decoration: none;
}

.slider_item_container {
    background-color: #e6ebe5;
}

.contact_card_inner_row {
    margin: 0;
}

/* contact card arrow */
.arrow_icon {
    background-color: rgb(224, 227, 227);
    height: 60px;
    width: 60px;
    position: absolute;
    left: 49%;
    top: 35%;
    transform: rotate(45deg);
}

.contact_card_body:hover .arrow_icon {
    background-color: #4a9cd2;
}

.contact_card_body:hover .right_div {
    background-color: var(--color14);

}

.contact_card_body:hover .right_div h1 {
    color: #fff;
}

.contact_card_body:hover .right_div h2 {
    color: #fff;
}

/* GLOSSARY TECHNOLOGY PAGE */
.glossary_coloumn {
    background-color: #f2f2f2;
    max-width: 31% !important;
    margin-right: 10px;
    height: 240px;
    overflow: hidden;
}

.glossary_coloumn:hover {
    cursor: pointer;
    color: #f2f2f2;
    background-color: var(--color14);

}

.glossary_coloumn h1 {
    font-size: 19px !important;
    color: var(--color11);
}

/* STEEL TECHNOLOGY */
.steel_technology_img_div {
    width: 100%;
    padding: 0 !important;
}

.course_img_div {
    background-color: #ebebeb;
    padding-top: 100%;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.course_img_div img {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.course_arrow_icon {
    background-color: var(--bg1);
    height: 40px;
    width: 40px;
    position: absolute;
    left: 95%;
    top: 40%;
    display: inline;
    transform: rotate(44deg);
}

@media only screen and (max-width: 768px) {
    .course_arrow_icon {
        display: none;
    }
}

.course_container_row:hover .course_block_right_div {
    background-color: #4a9cd2;
    color: #fff;
}

.course_container_row:hover .block_right_div h3 {
    color: #f2f2f2;
}

.course_container_row:hover .block_right_div .news_date_container {
    color: #fff;
}

.course_container_row:hover .course_arrow_icon {
    background-color: #4a9cd2;
}



/* scrollbar_container contact card details page*/

.scrollbar_container {
    overflow-y: scroll;
    height: 300px;
    text-align: left;
    margin-bottom: 2rem;
    padding: 12px;
}

#vision-content>div h1 {
    font-family: 'Noto Serif', serif;
    font-weight: 700 !important;
    font-size: 38px !important;
    word-spacing: 5px;
}

/* READMORE POPUP CSS */
#read_more_div {
    position: absolute;
    top: 0% !important;
    padding-right: 2rem;
    max-width: 774px;
}

.owl-nav {
    display: block;
    flex-direction: column !important;
    text-align: right !important;
}



.arrow_left,
.arrow_right {
    font-size: 18px !important;
    font-weight: bold;
    color: #000 !important;
    border: solid 2px rgb(12, 2, 2) !important;
    border-radius: 100px !important;
    padding: 0px 8px !important;
    margin: 5px !important;
    position: relative;
}


/* READ MORE GLOSARY PAGE CSS */
.glossary_coloumn h1 {
    color: var(--color11) !important;
}

.glossary_coloumn p {
    font-size: 15px !important;
}

.glossary_coloumn button {
    text-decoration: underline;
}

.glossary_coloumn button:hover {
    background-color: var(--color16);
    padding: 2px;
    border-radius: 2px;
}

.glossary_coloumn button:active {

    outline: none !important;
}

/* GLOSSARY BOX > READ MORE CSS */

#read_more_div {
    min-height: 290px;
}

#read_more_div p {
    font-size: 16px !important;
}

.in_the_news_btn {
    position: absolute;
    bottom: 4%;
    right: 3%;
}

.member_btn a {
    text-decoration: none;
    font-family: 'Noto Serif', serif;
}

.member_btn a:hover {
    color: #f2f2f2;

}

/* commitee contact card css */
.right_div h2 {
    font-size: 12px !important;
}

.about_left_container {
    position: absolute;
    top: 0;
    background-color: rgb(38 70 115 / 90%);
    width: 70%;
}


@media only screen and (max-width: 768px) {
    .member_btn {
        padding: 4px !important;
        font-size: 12px !important;
        margin-left: -8px !important;
    }

    .member_btn a span {
        padding-left: 2px !important;
    }

    /* SCROLLBAR CONTAINER MEDIA QUERY */
    .scrollbar_row_container {
        padding: 2px !important;
    }

    .scrollbar_main_img_col {
        padding-right: 0 !important;
    }

    .contact_details_close_btn {
        margin: 2px !important;
    }

    .scrollbar_container {
        width: 74vw;
        padding-left: 0;
    }

    .block_right_div h3 {
        font-size: 16px !important;
    }
}

.in_the_news_date {
    background-color: var(--color11);
    padding: 5px;
    text-align: right;
    color: #fff;
    position: relative;
    bottom: 50px;
    right: -20px;
}

/* LOGIN & LOGOUT CSS */
.Extranet-btn:hover .logo {
    display: none !important;
}

.Conference {
    background-color: var(--color12);
    border-color: var(--color12);
    ;
}

.Event {
    background-color: var(--color13);
    border-color: var(--color13);
    ;
}

.Meeting {
    background-color: var(--color16);
    border-color: var(--color16);
    ;
}


.story_details_hr {
    width: 100%;
    background-color: var(--color23);
}

.story_details_par_div {
    padding-top: 40%;
}

.story_details_short_description {

    position: absolute;
    bottom: 0;
}

.our_story_latest_box {
    border-left: solid 1px #ededed;
    border-right: solid 1px #ededed;
}

.our_story_latest_box:hover a {
    text-decoration: none;
}

.our_story_details_description {
    padding-left: 7rem;
    padding-right: 7rem;
}


@media only screen and (max-width:768px) {
    .overlaped_h1 {
        position: relative;
        top: -2rem;
        margin: 0 2rem !important;
    }

    .overlaped_h1 span {
        font-size: 15px !important;
        line-height: 45px !important;
    }

    .our_story_details_description {
        padding-left: 0rem;
        padding-right: 0rem;
    }

    .LatestNews_row {
        flex-direction: column;
    }
}

/* Steel Trends */
.steel_trends {
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;
}

.steel_trends h4 {
    font-size: 20px;
    font-weight: 700;
    color: #00357d;
    text-transform: uppercase;
    border-bottom: 2px solid #00357d;
    padding-bottom: 10px;
    margin-bottom: 4px;
    text-align: start;
}

.steel_trends .sub_menu {
    font-size: 12px;
    padding: 8px 0;
    color: #303030;
    text-align: start;
    border-bottom: 1px solid #bfc4ccab;
    cursor: pointer;
    padding-left: 10px;
}

.steel_trends .sub_menu.active {
    font-weight: 900;
    font-size: 14px;
}

.steel_trends .menu {
    font-size: 16px;
    padding: 8px 0;
    color: #303030;
    text-align: start;
    border-bottom: 1px solid #bfc4ccab;
    display: flex;
    justify-content: start;
}

.steel_trends .menu.active {
    font-weight: 900;
    font-size: 18px;
}

.steel_trends .trend-table-head {
    display: flex;
    justify-content: end;
    align-items: flex-end;
    margin-bottom: 10px;
}

.steel_trends .steel_trend_table table {
    width: 100%;
    min-width: 500px;
    overflow: auto;
}

.steel_trends .steel_trend_table {
    overflow: auto;
}

.steel_trends .steel_trend_table table tbody tr:nth-child(2n+1) {
    background-color: rgba(226, 223, 223, 0.731);
}

.steel_trends .steel_trend_table table tbody tr:first-child {
    background-color: grey;
    color: white;
}

.steel_trends .steel_trend_table table tbody td {
    border: 1px solid #ccc;
    border-collapse: collapse;
    font-size: 13px;
    color: #2f2f2f;
    font-weight: 400;
    padding: 10px 7px;
    vertical-align: top;
}

.steel_trends .steel_trend_table table tbody tr {
    cursor: pointer;
}