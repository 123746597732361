/* * { box-sizing: border-box; }

body { font-family: sans-serif; } */

.carousel {
  background: #FAFAFA;
  margin-bottom: 40px;
}

.carousel-cell {
  width: 66%;
  height: 200px;
  margin-right: 10px;
  background: #8C8;
  border-radius: 5px;
  /* counter-increment: carousel-cell; */
}

.carousel-cell:before {
  display: block;
  text-align: center;
  /* content: counter(carousel-cell); */
  line-height: 200px;
  font-size: 80px;
  color: white;
}

.carousel-progress {
  position: relative;
  margin: 0 auto;
  width: 66%;
  height: 8px;
  border-radius: 4px;
  background-color: #cec;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  border-radius: 4px;
  width: 0%;
  background-color: #8c8;
}