.calender_year__block {
  border: solid gray 1px;
  color: gray;
  font-size: 15px;
  text-align: left;
  text-align: center;
}

.calender_year__block:hover {
  border: none;
  background-color: #50b3dd;
  color: white;
  cursor: pointer;
}

.signup_box {
  background-color: #50b3dd;
  padding: 16px;
  color: white;
}

.event_block_row {
  background-color: var(--bg1);
  min-height: 100px;
}
.event_block_row div {
  overflow: hidden;
}

.new_date {
  color: #50b3dd;
  font-weight: bold;
}

.sub_heading_menu {
  width: 100%;
  background-color: var(--bg1);
}

.sub_heading_menu ul li {
  list-style: none;
  display: inline;
}

.loading_box {
  width: 100%;
  height: 200px;
  background: linear-gradient(271deg, var(--bg1), var(--color16));
  background-size: 400% 400%;
  -webkit-animation: AnimationName 4s ease infinite;
  -moz-animation: AnimationName 4s ease infinite;
  animation: AnimationName 4s ease infinite;
}

.main_loading_box {
  width: 100%;
  height: 63vh;
  background: linear-gradient(271deg, var(--bg1), var(--color16));
  background-size: 400% 400%;
  -webkit-animation: AnimationName 4s ease infinite;
  -moz-animation: AnimationName 4s ease infinite;
  animation: AnimationName 4s ease infinite;
}
.twitter_loading_box {
  width: 100%;
  height: 450px;
  background: linear-gradient(271deg, var(--bg1), var(--color16));
  background-size: 400% 400%;
  -webkit-animation: AnimationName 4s ease infinite;
  -moz-animation: AnimationName 4s ease infinite;
  animation: AnimationName 4s ease infinite;
  overflow: auto;
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.img_div {
  background-color: #ebebeb;
  padding-top: 100%; /* 5:6 Aspect Ratio */
  position: relative;
  width: 100%;
  overflow: hidden;
}
.img_div img {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
}

.btn-contact:hover {
  background-color: var(--color21) !important;
}
button.bg-light:hover {
  background-color: var(--color21) !important;
}

.managment-name {
  font-weight: 600;
}
#btn-container .col {
  overflow: hidden;
}

#btn-container .col a {
  width: 100%;
  text-align: left;
  color: var(--color11);
}
#nav_link_hover:hover {
  font-weight: 600;
}
.left_menu {
  margin: 10px 5px;
  font-size: 1rem;
  font-style: normal;
  cursor: pointer;
  list-style: none;
  list-style-type: disc;
  list-style-position: outside;
}

.left_menu a {
  text-decoration: none;
}
.left_menu a:hover {
  font-weight: 600;
}
#nav_link_hover:hover {
  cursor: pointer;
  font-weight: 600 !important;
}

.active-left_menu {
  font-weight: 600;
}

.contact_card_body {
  padding: 0 !important;
}

#contact_detail {
  position: absolute;
  z-index: 1;
}
#contact_detail img {
  width: 100%;
}
#slider2 {
  margin-top: 3px;
}
.contact_card_carousel_img {
  cursor: pointer;
  background-color: white;
}
#contact_detail .owl-carousel {
  opacity: 1;
}

.contact_card_div {
  cursor: pointer;
}
.md_h_flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.affiliate_member img {
  display: inline;
}

.block_img_div {
  padding-top: 75%;
}
.block_img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.block_right_div {
  padding-top: 25%;
}
.block_right {
  position: absolute;
  top: 0;
}

.filter_active {
  border: none;
  background-color: #50b3dd;
  color: white;
}
.clear_filter {
  cursor: pointer;
  position: absolute;
  right: 20px;
  color: var(--color21);
}
.sign_up_muted {
  color: #fff !important;
}

.overlaped_h1 {
  position: relative;
  top: -2rem;
  margin: 0 4rem;
}
.overlaped_h1 span {
  background-color: var(--color21);
  /* background-color: var(--color23); */
  padding: 10px;
  font-size: 30px;
  line-height: 84px;
  font-weight: bold;
}
.highlighted_story_box {
  background: #ededed;
  padding: 2rem;
}

.member_types div a {
  padding: 20px 0;
  font-weight: bold;
  text-align: center;
  color: #fff !important;
  background-color: var(--color12);
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color16);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color11);
}
/* scrollbar */
.description_box p,
.description_box ul,
.description_box a {
  font-size: 1rem;
}
.visit_website_a {
  text-decoration: underline;
  text-decoration-color: var(--color21);
  text-decoration-thickness: 3px;
}
