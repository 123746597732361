@media only screen and (max-width:768px) {
    .left_container .row{
        margin-top: 2rem !important;
    }
    .vison_top_heading_container{
        height: 290px !important;
        position: absolute;
    }
    .vison_top_heading_container h1{
        font-size: 1.5rem !important;
    }
    .vison_top_heading_container h2{
        font-size: 1.7rem !important;
    }
    .vison_top_para{
        line-height: 1.2 !important;
        padding: 0 !important;
        font-size: 1rem;
    }
    .vison_heading2{
        min-width: 220px !important;
    }  

#vision-content p{
    font-size: 1rem;
}
.vision_para{
    font-size: 1rem;
}
.mission_para{
    font-size: 14px;
}
.right_banner{
    display: none !important;
}
#vision-content> div h1{
    font-size: 1.1rem !important;
}
.vision_container{
    padding-bottom: 5px !important;
}
.mission_container{
    padding: 5px !important;
}
.mission_para{
    font-size: 1rem !important; 
}
/* OUR TEAM CSS */

.banner_heading_container h1{
    font-size: 1.6rem !important;
}
.banner_heading_container h2{
    font-size: 1.6rem !important;
}
.banner_heading_container p{
    font-size: 1rem !important;
}
/* ABOUT CONTACT */
.container-contact{
    padding: .5rem !important;
}
.container_contact_row{
    padding-top: 0 !important;
}

.social_media_contact .fa-brands {
    font-size: 1 rem!important;
}
.container-contact p {
    font-size: 14px;
}
.contact_us_social li{
    font-size: 1rem;
    margin-left: 0 !important;
}
.about_contact_map{
    justify-content: center;
    padding-right: 1rem !important;
}
.about_contact_map iframe {
    max-width: 100% !important;
}
.right_div h1 {
    font-size: 16px !important;
}
}