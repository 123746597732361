.vison-container{
    background-image: url(https://thumbs.dreamstime.com/z/multistory-building-amoy-city-blue-sky-reflected-modern-mirror-glass-wall-35752538.jpg);
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

#mission{
    border-radius: 50%;
    margin: 10px;
    justify-content: center;
    display: flex;
}



.btn-link button{
    background: var(--bg14);
    margin-right: 1.5rem;
}
.btn-link button a{
    text-decoration: none;
}
/* 
#vishaka-comitee-banner{
    background-image: url(https://images.freeimages.com/images/large-previews/018/glass-and-steel-1223771.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
} */

